/* .sg-top-logo {
    max-width: @logo-max-width;
    max-height: @sg-top-height;
    padding: @padding;
} */
.sg-header-nav {
  margin-bottom: 1em;
}
.sg-top-buttons {
  height: 100px;
  padding-right: 12px;
}
.sg-top-buttons p {
  line-height: 100px;
}
.sg-top-buttons .button {
  margin: 6px;
}
