@logo-max-width: 250px;
@sg-top-height: 100px;

@padding: 12px;

/* .sg-top-logo {
    max-width: @logo-max-width;
    max-height: @sg-top-height;
    padding: @padding;
} */

.sg-header-nav {
    margin-bottom: 1em;
}

.sg-top-buttons {
    height: @sg-top-height;
    padding-right: @padding;

    p {
        line-height: @sg-top-height;
    }

    .button {
        margin: @padding / 2;
    }
}